@import './colors';

$input-height: 40px;
$height-btn-primary: 70px;
$input-indent: 25px;
/* New box-shadows */
$box-shadow-wrapper: 0 3px 10px rgba(29, 36, 48, 0.08);
$box-shadow-modal: 0 5px 10px rgba(29, 36, 48, 0.15);
$box-shadow-popover: 0 8px 15px rgba(29, 36, 48, 0.2);

$modalWidth: 400px;
$modalHeight: 202px;

$gt-pressura: GTPressuraPro, sans-serif;
$gt-pressura-bold: GTPressuraProBold, sans-serif;

$pt-sans: PTSans, sans-serif;
$pt-sans-bold: PTSansBold, sans-serif;

$transition: all 140ms cubic-bezier(0.26, 0.59, 0.75, 0.74);

/*
  Медиа для сворачивания меню
 */
$break-large: 1399px;

/*
  Миксин для обрезания текста по ширине
 */
@mixin text-truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
