@import "../../../styles/consts";

.popup {
  background-color: $primary;
  padding: 15px 20px;
  color: $white;
  border-radius: 3px;
  max-width: 330px;
  width: 330px;
  white-space: normal;

  & > span::after {
    border-color: transparent transparent $primary transparent !important;
  }
}

.reviewSuggestionPopup {
  height: 175px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.popupContent {
  max-width: 240px;
}

.closeIcon {
  &:hover {
    cursor: pointer;
  }
}

.reviewSuggestionModalTrigger {
  display: block;
  position: absolute;
  height: 1px;
  width: 1px;
  right: 40px;
  bottom: 40px;
}
