@import './colors.scss';
@import './consts.scss';

//Restyle Autocomplete Start
.react-autosuggest__container {
  width: 100%;
}

.react-autosuggest__suggestions-container--help-snippet {
  display: none;
  padding: 10px 15px;
  color: transparentize($darker, 0.4);
  border-bottom: 1px solid $lightest;
}

.react-autosuggest__suggestions-container--help-snippet-icon {
  opacity: 0.4;
  margin-right: 5px;
}

.react-autosuggest__suggestions-container--open {
  color: $darker;
  .react-autosuggest__suggestions-container--help-snippet {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
//Restyle Autocomplete End
