@import '../../../../styles/consts';

.pulse {
  box-shadow: 0 0 0 0 rgba($danger, 0.1);
  transform: scale(1);
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($danger, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 6px rgba($danger, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($danger, 0);
  }
}
