@import './colors';
@import './consts';

.toaster {
  &.container {
    width: 380px;
    padding: 0;
    right: 30px;

    .toast {
      font-family: $pt-sans;
      min-height: 40px;
      box-shadow: $box-shadow-modal;
      background-color: $white;
      padding: 0;
      border-top: none;
      border-left: 2px solid;
      border-radius: 3px;

      &.success {
        border-left-color: $success;
      }

      &.error {
        border-left-color: $danger;
      }

      &.warning {
        border-left-color: $warning;
      }

      &.info {
        border-left-color: $base;
      }
    }

    &.Toastify__toast-container--top-right {
      top: calc(50px + 1em);
    }
  }
}
