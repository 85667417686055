@font-face {
  font-family: 'GTPressuraPro';
  src: url('../assets/fonts/GTPressuraPro/GT-Pressura-Pro-Regular.woff2') format('woff2'),
       url('../assets/fonts/GTPressuraPro/GT-Pressura-Pro-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'GTPressuraProBold';
  src: url('../assets/fonts/GTPressuraPro/GT-Pressura-Pro-Bold.woff2') format('woff2'),
       url('../assets/fonts/GTPressuraPro/GT-Pressura-Pro-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'PTSansBold';
  src: url('../assets/fonts/PTSans/PTSans-Bold.woff2') format('woff2'),
  url('../assets/fonts/PTSans/PTSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PTSans';
  src: url('../assets/fonts/PTSans/PTSans-Regular.woff2') format('woff2'),
  url('../assets/fonts/PTSans/PTSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
