@import './colors';
@import './consts';

@for $i from 0 through 30 {
  .mt-#{$i} {
    margin-top: 5px * $i;
  }

  .mb-#{$i} {
    margin-bottom: 5px * $i;
  }

  .ml-#{$i} {
    margin-left: 5px * $i;
  }

  .mr-#{$i} {
    margin-right: 5px * $i;
  }

  /* Negative margin */
  .nmt-#{$i} {
    margin-top: -5px * $i;
  }

  .nmb-#{$i} {
    margin-bottom: -5px * $i;
  }

  .nml-#{$i} {
    margin-left: -5px * $i;
  }

  .nmr-#{$i} {
    margin-right: -5px * $i;
  }
}

.ma {
  margin: auto;
}

.ml-a {
  margin-left: auto;
}

.mr-a {
  margin-right: auto;
}

@for $i from 0 through 20 {
  .pt-#{$i} {
    padding-top: 5px * $i;
  }

  .pb-#{$i} {
    padding-bottom: 5px * $i;
  }

  .pl-#{$i} {
    padding-left: 5px * $i;
  }

  .pr-#{$i} {
    padding-right: 5px * $i;
  }
}

.color {
  &-white {
    color: $white;
  }

  &-black {
    color: black;
  }

  &-base {
    color: $base;
  }

  &-traffic-blue {
    color: $traffic-blue;
  }

  &-darker {
    color: $darker;
  }

  &-default,
  &-dark {
    color: $dark !important;
  }

  &-light {
    color: $light;
  }

  &-lighter {
    color: $lighter;
  }

  &-lightest {
    color: $lightest;
  }

  &-label {
    color: transparentize($darker, 0.2);
  }

  &-primary {
    color: $primary;
  }

  &-primary-dark {
    color: $primary-dark;
  }

  &-danger {
    color: $danger;
  }

  &-danger-dark {
    color: $danger-dark;
  }

  &-success {
    color: $success;
  }

  &-success-dark {
    color: $success-dark !important;
  }

  &-warning {
    color: $warning;
  }

  &-warning-dark {
    color: $warning-dark;
  }

  &-viber {
    color: $viber;
  }
}

/* BG colors */
.bg-color {
  &-base {
    background-color: $base;
  }

  &-white {
    background-color: $white !important;
  }

  &-primary-light {
    background-color: $primary-light;
  }

  &-primary {
    background-color: $primary;
  }

  &-primary-dark {
    background-color: $primary-dark;
  }

  &-traffic-blue {
    background-color: $traffic-blue;
  }

  &-traffic-red {
    background-color: $traffic-red;
  }

  &-default,
  &-lighter {
    background-color: $lighter !important;
  }

  &-lightest {
    background-color: $lightest !important;
  }

  &-light {
    background-color: $light;
  }

  &-dark {
    background-color: $dark;
  }

  &-darker {
    background-color: $darker;
  }

  &-warning {
    background-color: $warning;
  }

  &-success {
    background-color: $success;
  }

  &-danger {
    background-color: $danger;
  }

  &-danger-dark {
    background-color: $danger-dark;
  }

  &-warning-light {
    background-color: $warning-light !important;
  }

  &-warning-dark {
    background-color: $warning-dark;
  }

  &-success-light {
    background-color: $success-light !important;
  }

  &-success-dark {
    background-color: $success-dark;
  }

  &-danger-light {
    background-color: $danger-light !important;
  }

  &-viber {
    background-color: $viber;
  }
}

.d-none {
  display: none !important;
}

/* Flex model */
.flex {
  display: flex;

  &-inline {
    display: inline-flex !important;
  }

  &.items-start {
    align-items: flex-start;
  }

  &.items-center {
    align-items: center;
  }

  &.items-end {
    align-items: flex-end;
  }

  &.items-baseline {
    align-items: baseline;
  }

  &.content-start {
    justify-content: flex-start;
  }

  &.content-between {
    justify-content: space-between;
  }

  &.content-around {
    justify-content: space-around;
  }

  &.content-end {
    justify-content: flex-end;
  }

  &.content-center {
    justify-content: center;
  }

  &.wrap {
    flex-wrap: wrap;
  }
}

.align-center {
  align-self: center;
}

.align-end {
  align-self: end;
}

.no-shrink {
  flex-shrink: 0;
}

.flex-1 {
  flex: 1 1 100%;
}

.flex-0 {
  flex: 0 !important;
}

.column {
  flex-direction: column;
}

.column-reverse {
  flex-direction: column-reverse;
}

.row {
  flex-direction: row;
}

.row-reverse {
  flex-direction: row-reverse;
}

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}

.link {
  color: $primary;
  font-family: inherit;
  font-weight: inherit;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;
  overflow: inherit;
  text-overflow: inherit;
  transition: $transition;

  &.link-dashed {
    border-bottom: 1px dashed $primary;
  }

  &:visited {
    color: $primary;
  }

  &:hover {
    color: $primary-dark;
    cursor: pointer;
  }
}

.width100p {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

.width50p {
  width: 50%;
  max-width: 50%;
  min-width: 50%;
}

.width20 {
  flex: 0 0 20px;
  width: 20px;
}

.width40 {
  flex: 0 0 40px;
  width: 40px;
}

.width65 {
  flex: 0 0 65px;
  width: 65px;
}

.width75 {
  flex: 0 0 75px;
  width: 75px;
}

.width80 {
  flex: 0 0 80px;
  width: 80px;
}

.width85 {
  flex: 0 0 85px;
  width: 85px;
}

.width90 {
  flex: 0 0 90px;
  width: 90px;
}

.width100 {
  flex: 0 0 100px;
  width: 100px;
}

.width110 {
  flex: 0 0 110px;
  width: 110px !important;
}

.width120 {
  flex: 0 0 120px;
  width: 120px;
}

.width125 {
  flex: 0 0 125px;
  width: 125px;
}

.width130 {
  flex: 0 0 130px;
  width: 130px;
}

.width135 {
  flex: 0 0 135px;
  width: 125px;
}

.width140 {
  flex: 0 0 140px;
  width: 140px;
}

.width145 {
  flex: 0 0 145px;
  width: 145px;
}

.width150 {
  flex: 0 0 150px;
  width: 150px;
}

.width155 {
  flex: 0 0 155px;
  width: 155px;
}

.width160 {
  flex: 0 0 160px;
  width: 160px;
}

.width170 {
  flex: 0 0 170px;
  width: 170px;
}

.width175 {
  flex: 0 0 175px;
  width: 175px;
}

.width180 {
  flex: 0 0 180px;
  width: 180px !important;
}

.width195 {
  flex: 0 0 195px;
  width: 195px;
}

.width200 {
  flex: 0 0 200px;
  width: 200px;
}

.width205 {
  flex: 0 0 205px;
  width: 205px;
}

.width210 {
  flex: 0 0 210px;
  width: 210px;
}

.width215 {
  flex: 0 0 215px;
  width: 215px;
}

.width225 {
  flex: 0 0 225px;
  width: 225px;
}

.width230 {
  flex: 0 0 230px;
  width: 230px;
}

.width235 {
  flex: 0 0 235px;
  width: 235px;
}

.width240 {
  flex: 0 0 240px;
  width: 240px !important;
}

.width255 {
  flex: 0 0 255px;
  width: 255px;
}

.width260 {
  flex: 0 0 260px;
  width: 260px;
}

.width270 {
  flex: 0 0 270px;
  width: 270px !important;
}

.width275 {
  flex: 0 0 275px;
  width: 275px !important;
}

.width280 {
  flex: 0 0 280px;
  width: 280px;
}

.width290 {
  flex: 0 0 290px;
  width: 290px;
}

.width300 {
  flex: 0 0 300px;
  width: 300px;
}

.width305 {
  flex: 0 0 305px;
  width: 305px;
}

.width310 {
  flex: 0 0 310px;
  width: 310px;
}

.width320 {
  flex: 0 0 320px;
  width: 320px;
}

.width340 {
  flex: 0 0 340px;
  width: 340px !important;
}

.width350 {
  flex: 0 0 350px;
  width: 350px !important;
}

.width370 {
  flex: 0 0 370px;
  width: 370px;
}

.width380 {
  flex: 0 0 380px;
  width: 380px;
}

.width400 {
  flex: 0 0 400px;
  width: 400px !important;
}

.width410 {
  flex: 0 0 410px;
  width: 410px !important;
}

.width420 {
  flex: 0 0 420px;
  width: 420px;
}

.width430 {
  flex: 0 0 430px;
  width: 430px;
}

.width435 {
  flex: 0 0 435px;
  width: 435px;
}

.width450 {
  flex: 0 0 450px;
  width: 450px;
}

.width500 {
  flex: 0 0 500px;
  width: 500px;
}

.width550 {
  flex: 0 0 550px;
  width: 550px;
}

.width630 {
  flex: 0 0 630px;
  width: 630px;
}

.width730 {
  flex: 0 0 730px;
  width: 730px!important;
}

.width760 {
  flex: 0 0 760px;
  width: 760px;
}

.m-width-unset {
  max-width: unset !important;
}

.h100 {
  height: 100%;
}

.w-full {
  width: 100%;
}

.wa {
  width: auto;
}

.wmc {
  width: max-content;
}

.wfc {
  width: fit-content;
}

.height20 {
  height: 20px !important;
}

.height30 {
  height: 30px;
}

.height40 {
  height: 40px;
}

.min-height40 {
  min-height: 40px;
}

.height60 {
  height: 60px;
}

.height80 {
  height: 80px;
}

/* Центрируем значок Руб в инпутах с лейблом */
.additionalTranslateY + span {
  transform: translateY(50%);
}

.tooltip-icon-height {
  > span:not([type='checkbox']) {
    height: 20px;
  }
}

@for $i from 0 through 10 {
  .opacity-#{$i} {
    opacity: 0.1 * $i;
  }
}

@for $i from 0 through 20 {
  .br-#{$i} {
    border-radius: 1px * $i;
  }
}

.wrapper {
  background-color: $white;
  box-shadow: $box-shadow-wrapper;
  border-radius: 5px;
}

.block {
  padding: 25px 20px;
  background-color: $white;
  border-radius: 5px;

  & + & {
    margin-top: 5px;
  }

  &Title {
    padding: 15px 20px 10px 20px;
  }
}

.asideBlock {
  min-height: 50px;
  padding: 15px 25px;
  background-color: $white;
  border-radius: 5px;

  & + & {
    margin-top: 5px;
  }
}

.asideBlockNotification {
  @extend .asideBlock;
  box-shadow: inset 50px 0 0 -48px $base;
}

.categoryLogo {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;
  background-color: transparent;
  border: 1px solid $lighter;
  border-radius: 50%;
}

div.tooltip {
  max-width: 280px;
  padding: 15px 20px;

  background-color: transparentize($darker, 0.1);
  border-radius: 3px;
  box-shadow: $box-shadow-modal;
  color: $white;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.4px;
  text-align: left;
  white-space: normal;
  word-break: break-word;
}
.tooltipArrow__white {
  & span[data-placement^=top]:after {
    border-color: white transparent transparent transparent;
  }
}

.icon:hover {
  cursor: pointer;
  transition: $transition;

  path {
    fill: $primary;
  }
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-wrap {
  white-space: normal !important;
}

.break-word {
  word-break: break-word;
  word-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.text-dot-underline {
  border-bottom: 1px dotted $primary !important;
  padding: 0;
  border-radius: 0 !important;
}

.text-underline {
  text-decoration: underline;
}

.text-line-through {
  text-decoration: line-through;
}

.badge {
  display: inline-flex;
  height: 20px;
  padding: 0 10px;
  border-radius: 15px;
}

.badgeNew {
  width: 21px;
  height: 10px;
  padding: 0 2px;
  border-radius: 2px;
  font-size: 8px;
  line-height: 10px;
  margin-top: 2px;
  margin-left: 2px;

  &-pa {
    position: absolute;
    margin-top: 0;
  }
}

.text-align {
  &-right {
    text-align: right;
  }

  &-left {
    text-align: left;
  }

  &-center {
    text-align: center;
  }
}

.text-bold {
  font-family: $pt-sans-bold;
}

.text-capitalize {
  text-transform: capitalize;
}

.not-allowed {
  opacity: 0.4;
  cursor: not-allowed;

  & > button {
    pointer-events: none;
  }
}

.data-truncate {
  @include text-truncate;
}

.p-relative {
  position: relative;
}

.p-ab {
  position: absolute;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.cursor-pointer {
  cursor: pointer;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}
.fs-32 {
  font-size: 32px;
}

.leading-3 {
  line-height: 15px;
}
.leading-4 {
  line-height: 20px;
}
.leading-5 {
  line-height: 25px;
}
.leading-6 {
  line-height: 30px;
}
.leading-8 {
  line-height: 40px;
}

.custom-scrollbar {
  scrollbar-gutter: stable;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 20px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: white;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #cecece;
  border-radius: 20px;
  border: 8px solid white;
}

.bold {
  font-family: $pt-sans-bold;
}

.fw-400 {
  font-weight: 400;
}

.fw-700 {
  font-weight: 700;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: $light;
}
.divider-lighter {
  height: 1px;
  width: 100%;
  background-color: $lighter;
}

.toasterMessageText {
  a {
    color: $primary;

    &:hover {
      color: $primary-dark;
      cursor: pointer;
    }
  }
}

.mainFont__base {
  font-family: $pt-sans;
}
