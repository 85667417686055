@import './consts';
@import '~react-datepicker/dist/react-datepicker.min.css';

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  font-family: $pt-sans;
  font-size: 14px;
  color: $darker;
  border: none;
  border-radius: 5px;
  box-shadow: $box-shadow-wrapper;
  background-color: $white;
}

.react-datepicker {
  display: flex;
  border: none;
  border-radius: 0;
}

.react-datepicker-popper[data-placement^='bottom'] {
  margin-top: 5px;
  .react-datepicker__triangle {
    border-bottom-color: $white;
  }
}

.react-datepicker__header {
  margin-top: 15px;
  padding: 0;
  border: none;
  background-color: $white;
}

.react-datepicker__input-container {
  .react-datepicker__close-icon {
    padding-right: 10px;
    &:after {
      opacity: 0;
      background-color: transparent;
      width: 20px;
      height: 20px;
      color: $base;
      padding: 0;
      border-radius: 0;
      font-size: 20px;
      transition: $transition;
    }
  }

  &:hover {
    .react-datepicker__close-icon {
      &:after {
        opacity: 1;
      }
    }
  }
}

.react-datepicker__current-month {
  margin: 0;
  font-family: $pt-sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  text-transform: capitalize;
}

.react-datepicker__month {
  margin: 5px 20px 20px;
}

.react-datepicker__month-wrapper {
  margin-bottom: 10px;

  :hover {
    background-color: $lighter;
  }
}

.react-datepicker__month-text {
  width: 70px;
  height: 70px;
  line-height: 70px;
  vertical-align: middle;
  margin: 0 0 0 25px;

  &:first-child {
    margin: 0;
  }
}

.react-datepicker__month--selected {
  background-color: $light;
  color: $darker;

  &:hover {
    background-color: $light !important;
  }
}

.react-datepicker__day {
  color: $darker;
  font-family: $pt-sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.25px;
  text-align: center;
  width: 35px;
  height: 35px;
  margin: 0;
  line-height: 35px;
  border-radius: 3px;
  transition: $transition;

  &:hover {
    background-color: $lightest;
  }
}

.react-datepicker__day-names {
  margin: 0 20px;
  line-height: 15px;
}

.react-datepicker__day-name {
  font-family: $pt-sans;
  font-size: 12px;
  line-height: 15px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  color: $dark;
  width: 35px;
  margin: 0;
}

.react-datepicker__day--today {
  color: $darker;
  background-color: $light;
  &:hover {
    color: $darker;
    background-color: $light;
  }
}

.react-datepicker__day--selected {
  color: $white;
  background-color: $primary;
  &:hover {
    color: $white;
    background-color: $primary;
  }
}

.react-datepicker__day--keyboard-selected {
  color: inherit;
  background-color: inherit;
}

.react-datepicker__day--disabled {
  cursor: not-allowed;
  color: $base;
  background-color: transparent;
  &:hover {
    color: $base;
    background-color: transparent;
  }
}

.react-datepicker__day--outside-month {
  color: $base;
  pointer-events: none;
  background-color: transparent !important;
}

// Timepicker
.react-datepicker__time-container {
  border-left: 1px solid $lighter;
  width: 120px;

  .react-datepicker__header--time {
    height: 50px;
    margin: 0;
    border-bottom: 1px solid $lighter;

    .react-datepicker-time__header {
      font-family: $pt-sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0.25px;
      text-align: center;
      padding: 15px 0;
    }
  }

  .react-datepicker__time {
    .react-datepicker__time-box {
      width: 120px;
      .react-datepicker__time-list {
        min-height: 220px !important;

        .react-datepicker__time-list-item {
          font-size: 12px;
          line-height: 20px;
          color: $darker;
          height: 30px;
          width: 65px;
          border-radius: 3px;
          transition: $transition;
          margin: 0 auto;
          &:hover {
            background-color: $lightest !important;
          }
        }

        .react-datepicker__time-list-item--selected {
          background-color: $primary !important;
          color: $white !important;
          &:hover {
            background-color: $primary !important;
            color: $white !important;
          }
        }

        .react-datepicker__time-list-item + .react-datepicker__time-list-item {
          margin-top: 5px;
        }

        .react-datepicker__time-list-item:first-of-type {
          margin-top: 10px;
        }

        .react-datepicker__time-list-item:last-of-type {
          margin-bottom: 10px;
        }
      }
    }
  }
}
