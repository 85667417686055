@import './consts';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  color: $darker;
  font: 14px/20px $pt-sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
  letter-spacing: 0.25px;
  // Only for IE
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $darker;
}

h1 {
  font-family: $gt-pressura-bold;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: 0;
}

h2 {
  font-family: $pt-sans-bold;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0;
}

h3 {
  font-family: $pt-sans-bold;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.25px;
}

h4 {
  font-family: $pt-sans-bold;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0;
}

sup {
  font-family: inherit;
  font-size: 10px;
}

.subtitle {
  font-family: $pt-sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.15px;
}

.caption {
  color: $dark;
  font-family: $pt-sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.4px;
}

.comment {
  font-family: $pt-sans-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: $darker;
}

ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
  font-size: inherit;
  cursor: pointer;
}

button,
h6 {
  font-family: $pt-sans-bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

menu {
  margin: 0;
  padding: 0;
}

span {
  font-family: inherit;
  font-size: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: $lightest;
  overflow: hidden;
}

.logoutWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.contentWrapper {
  flex: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  position: relative;
  overflow: hidden;
  width: 100%;
  flex-grow: 1;
}

.contentScroll {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  width: 100%;
}

.contentMain {
  display: flex;
  flex-direction: column;
  max-width: 1180px;
  margin: 30px auto 100px;

  & > div {
    width: 100%;
  }

  &-noAuth {
    flex: 1 1 auto;
    margin: 20px auto 50px;
  }

  &-onboarding {
    flex: 1 1 auto;
    margin: 20px auto 50px;
  }
}

.chunkLoadingStub {
  height: 100vh;
}

.formWrapper {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 50px;
  border-radius: 4px;
  box-shadow: $box-shadow-wrapper;
}

hr {
  display: block;
  height: 1px;
  margin: 0;
  position: absolute;
  border: 0;
  // в разных браузерах по-разному надо задавать цвет линии, поэтому указаны все случаи
  color: $light;
  background-color: $light;
  border-top: 1px solid $light;
}

form {
  position: relative;

  .buttonSave {
    width: auto;
    margin-top: 30px;
    padding: 0 30px;
  }

  .labelField {
    color: $darker;
    margin-top: $input-indent;
    display: block;
    font-size: 1rem;
    margin-bottom: 10px;
    font-family: $gt-pressura-bold;

    &Hidden {
      width: 1px;
      height: 1px;
      margin: 15px;
      border: 0;
      padding: 0;
      clip: rect(0 0 0 0);
      overflow: hidden;
    }
  }

  .item {
    width: 100%;

    &Half {
      width: calc((100% - 15px) / 2);
    }

    &Quarter {
      width: calc((100% - 45px) / 4);
    }
  }

  .checkboxField {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    label {
      font-size: 1rem;
      color: $darker;
      cursor: pointer;
    }
  }

  .blockIndent {
    &Small {
      margin-bottom: 25px;
    }

    &Big {
      margin-bottom: 50px;
    }
  }

  .blockInlineWrapper {
    display: flex;
    justify-content: space-between;
  }

  hr {
    width: 100%;
    margin: 25px 0 0 0;
    position: initial;
    height: 0;
  }
}

input {
  font-family: inherit;
  font-size: 100%;

  &::-ms-clear {
    display: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
}

input[type='email'] {
  box-shadow: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.noTopMargin {
  margin-top: 0;
}

/* Input Placeholder styles */
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: inherit;
  font-size: 100%;
  color: transparentize($darker, 0.6);
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-family: inherit;
  font-size: 100%;
  color: transparentize($darker, 0.6);
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-family: inherit;
  font-size: 100%;
  color: transparentize($darker, 0.6) !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-family: inherit;
  font-size: 100%;
  color: transparentize($darker, 0.6);
}

textarea {
  width: 100%;
  padding: 10px 15px;
  resize: none;
  height: 80px;

  border: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom: 1px solid $light;
  background-color: $lighter;

  font-family: $pt-sans;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;

  /* Textarea Placeholder styles */
  &::placeholder {
    color: $base;
  }

  /* hover, focus styles */
  &:hover,
  &:focus {
    border: none;
  }

  &:hover {
    border-bottom: 1px solid $base;
  }

  &:focus {
    border-bottom: 1px solid $primary;
  }
}

@media (min-width: 768px) {
  .contentMain {
    &-noAuth {
      margin: 70px auto 140px;
    }
    &-onboarding {
      margin: 20px auto 50px;
    }
  }
}

@media (min-width: 1280px) {
  .contentMain {
    &-noAuth {
      margin: 70px auto 140px;
    }
    &-onboarding {
      margin: 20px auto 50px;
    }
  }
}

.divider {
  height: 1px;
  background-color: $light;
}

.text-italic {
  font-style: italic;
}
