@import '../../styles/colors.scss';
@import '../../styles/consts.scss';

.pageHeader {
  position: relative;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  /* TODO: проверить на передописании */
  white-space: pre-line;
}

.backLink {
  position: absolute;
  width: auto;
  margin-left: 0;
  padding-left: 0;
}

.leftElement {
  position: absolute;
  width: auto;
  margin-left: 0;
  padding-left: 0;
  left: 0;
  transform: translateY(-50%);
  top: 50%;
}

.rightElement {
  position: absolute;
  width: auto;
  margin-right: 0;
  padding-right: 0;
  right: 0;
}

.header {
  margin: 0 auto;
}
