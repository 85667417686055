/* New colours */
/* Blue */
$primary: #0b5eda;
$primary-light: #dee8f7;
$primary-dark: #0048b2;

/* Green */
$success: #34b268;
$success-light: #e9fff2;
$success-dark: #248f51;

/* Yellow */
$warning: #f2b30f;
$warning-light: #fff7e3;
$warning-dark: #daa00b;

/* Red */
$danger: #de4818;
$danger-light: #fff4f1;
$danger-dark: #bd3a11;

/* Purple */
$purple: #a14af8;

/* GrayScale */
$darker: #1d2430; // = coal
$dark: #737d8c; // = silver?
$base: #98a3b3; // = onyx

$light: #cdd6e4; // new colour
$lighter: #ecf1f9; // = smoke
$lightest: #f4f7fb; // new
$white: #ffffff; // = snow

$traffic-blue: #1e4f98;
$traffic-red: #ec6c5c;

$viber: #7360f2;

/* stylelint-disable property-no-unknown  */
:export {
  primary: $primary;
  primary-light: $primary-light;
  primary-dark: $primary-dark;

  success: $success;
  success-light: $success-light;
  success-dark: $success-dark;

  warning: $warning;
  warning-light: $warning-light;
  warning-dark: $warning-dark;

  danger: $danger;
  danger-light: $danger-light;
  danger-dark: $danger-dark;

  purple: $purple;

  /* GrayScale */
  darker: $darker;
  dark: $dark;
  base: $base;
  light: $light;
  lighter: $lighter;
  lightest: $lightest;
  white: $white;

  traffic-blue: $traffic-blue;
  traffic-red: $traffic-red;

  viber: $viber;
  /* stylelint-enable */
}
