@import '../../../styles/consts.scss';
@import '../../../styles/colors.scss';

.input {
  all: unset;
  display: inline-block;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  box-sizing: border-box;
  background: $white;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $light;
  font-family: $pt-sans;
  color: $darker;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    background-color: $light !important;
  }
}

.inputError {
  border-color: $danger;
}

.chevron {
  position: absolute;
  right: 0;
  transform: translateX(-50%) translateY(50%) rotate(270deg);
}

.chevronUp {
  transform: translateX(-50%) translateY(50%) rotate(90deg);
}

.dropdown {
  width: 160px;
  max-height: 202px;

  .dropdownHeader {
    display: flex;
    align-items: center;
    height: 25px;
    border-bottom: 1px solid $light;

    > div {
      width: 50%;
      text-align: center;
    }
  }

  .dropdownBody {
    display: flex;

    .column {
      width: 50%;
      max-height: 150px;
      overflow-y: auto;
      &:last-of-type {
        box-shadow: -1px 0 0 0 $light;
      }
      > span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        &:hover {
          background-color: $lighter;
        }

        &.selected {
          background-color: $lighter;
        }
      }
    }
  }

  .dropdownFooter {
    border-top: 1px solid $light;
    height: 25px;
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 20px;
  }
}

.popoverClassName {
  padding: 0;
}
