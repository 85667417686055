@import '../../styles/consts.scss';
@import '../../styles/application.scss';

.switch {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: $input-height;
  background: $lighter;
  border-radius: 5px;
  border: 2px solid $lighter;

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50%;
    margin-top: 0 !important;
    margin-right: 2px;
    font-family: $pt-sans-bold;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: $dark;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: $transition;

    &:last-of-type {
      margin-right: 0;
    }

    &:hover {
      color: $primary-dark;

      svg {
        path {
          fill: $primary-dark;
        }
      }
    }
  }

  input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(100%);

    &:checked + label {
      background-color: $white;
      color: $primary;

      svg {
        path {
          fill: $primary;
        }
      }
    }

    &:disabled {
      pointer-events: none;

      & + label {
        background-color: $light;
        color: $base;
        cursor: not-allowed;
      }
    }

    &:checked + label:hover {
      color: $primary-dark;

      svg {
        path {
          fill: $primary-dark;
        }
      }
    }

    &:disabled:not(:checked) + label {
      background-color: $lighter;
    }

    &:checked:disabled + label:hover {
      color: $base;

      svg {
        path {
          fill: $base;
        }
      }
    }
  }
}

.comment {
  position: absolute;
  top: 41px;
  left: 0;
  color: $dark;
  font-family: $pt-sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.4px;
}

.switch.small {
  height: 30px;
}

.switch.tiny {
  height: 20px;

  label {
    font-size: 12px;
    line-height: 15px;
    font-family: $pt-sans;
    font-style: normal;
    font-weight: normal;
  }
}
