@import "./colors";

//.react-select-container
:global div.react-select__control {
  padding: 0;
  border: 0;
  background-color: transparent;
}

:global div.react-select__value-container {
  padding: 0;
}

:global div.react-select__placeholder {
  padding-left: 15px;
  margin-left: 0;
  color: transparentize($darker, 0.6);
}
//.react-select__indicators
//.react-select__menu
//.react-select__menu-list
//.react-select__option
